import React from "react";
import { job, StewardReportDetail_ui } from "../../../services/dtos";

interface MemberDetailHeaderRowsProps {
  selectedJob: job | null;
  handleStartMassText: () => void;
  detailedJobRecords: StewardReportDetail_ui[];
}

export const MemberDetailHeaderRows = ({
  selectedJob,
  handleStartMassText,
  detailedJobRecords,
}: MemberDetailHeaderRowsProps) => {
  return (
    <>
      {/* <tr>
        <td className="tg-6ic8">JOB NO:</td>
        <td className="tg-0lax" colSpan={5}>
          {selectedJob?.job_ID}
        </td>
        <td className="tg-amwm">LOCAL NO.</td>
        <td className="tg-amwm" colSpan={7}></td>
      </tr>
      <tr>
        <td className="tg-6ic8">PRODUCTION CO:</td>
        <td className="tg-0lax" colSpan={5}>
          {selectedJob?.pR_Co_ID}
        </td>
        <td className="tg-amwm">STEWARD</td>
        <td className="tg-amwm" colSpan={7}></td>
      </tr>
      <tr>
        <td className="tg-6ic8">LOCATION:</td>
        <td className="tg-0lax" colSpan={5}>
          {selectedJob?.report_To_Location}
        </td>
        <td className="tg-amwm">DAILY REPORT</td>
        <td className="tg-amwm" colSpan={7}></td>
      </tr>
      <tr>
        <td className="tg-6ic8">MASS TEXT:</td>
        <td className="tg-0lax" colSpan={5}>
          <span
            onClick={() => {
              if (detailedJobRecords.length > 0) {
                handleStartMassText();
              }
            }}
            style={{
              textDecoration: "underline",
              cursor: detailedJobRecords.length > 0 ? "pointer" : "default",
              color: detailedJobRecords.length > 0 ? "black" : "grey",
            }}
          >
            Start
          </span>
        </td>
      </tr>
      <tr>
        <td className="tg-0pky" colSpan={14}></td>
      </tr> */}
      <tr style={{ backgroundColor: "black", color: "white" }}>
        <td className="tg-bobw" rowSpan={2}>
          ACTIONS
        </td>
        <td className="tg-bobw" rowSpan={2}>
          EMPLOYEE NAME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          DEPT
        </td>
        <td className="tg-bobw" rowSpan={2}>
          RATE
        </td>
        <td className="tg-1wig"></td>
        <td className="tg-1wig" colSpan={2}>
          FIRST BREAK
        </td>
        <td className="tg-1wig" colSpan={2}>
          SECOND BREAK
        </td>
        <td className="tg-1wig"></td>
        <td className="tg-bobw" rowSpan={2}>
          OTHER RATE
        </td>
        <td className="tg-bobw" rowSpan={2}>
          OTHER QUANTITY
        </td>
        <td className="tg-bobw" rowSpan={2}>
          OTHER TOTAL
        </td>
        <td className="tg-bobw" rowSpan={2}>
          ST TIME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          1.5X TIME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          2x TIME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          DAILY TOTAL
        </td>
        <td className="tg-bobw" rowSpan={2}>
          COMMENTS
        </td>
      </tr>
      <tr style={{ backgroundColor: "black", color: "white" }}>
        <td className="tg-1wig">IN</td>

        <td className="tg-1wig">OUT</td>
        <td className="tg-1wig">IN</td>

        <td className="tg-1wig">OUT</td>
        <td className="tg-1wig">IN</td>

        <td className="tg-1wig">OUT</td>
      </tr>
    </>
  );
};
