import { StewardReportDetail_ui } from "../../services/dtos";
import {
  isEndTimeGreaterThanStartTime,
  setBasicTimeForSelectedJob,
  setSelectedJobIf1TimeExists,
  setSelectedJobIf2TimesExist,
  setSelectedJobIfAllTimesExist,
} from "./timesheetUtils";

export const setFirstBreakInTime = (
  storedJob: StewardReportDetail_ui,
  selectedJob: StewardReportDetail_ui,
  firstBreakInTime: string,
  timeId: any,
  detailedJobRecords: StewardReportDetail_ui[],
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >
) => {
  console.log(`setting ${timeId} - ${firstBreakInTime}`);
  if (
    isEndTimeGreaterThanStartTime(selectedJob.out_time_2, firstBreakInTime) &&
    isEndTimeGreaterThanStartTime(storedJob.in_time_1, firstBreakInTime)
  ) {
    setDetailedJobRecords(
      setSelectedJobIf2TimesExist(
        detailedJobRecords,
        selectedJob,
        firstBreakInTime,
        selectedJob.in_time_1,
        selectedJob.out_time_1,
        selectedJob.out_time_2,
        firstBreakInTime,
        timeId
      )
    );
  } else {
    console.log(`${timeId} - ${firstBreakInTime} is INVALID`);
    setDetailedJobRecords(
      setBasicTimeForSelectedJob(detailedJobRecords, selectedJob, "", timeId)
    );
  }
};

export const setFirstBreakOutTime = (
  storedJob: StewardReportDetail_ui,
  selectedJob: StewardReportDetail_ui,
  firstBreakOutTime: string,
  timeId: any,
  detailedJobRecords: StewardReportDetail_ui[],
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >
) => {
  console.log(`setFirstBreakOutTime, setting ${timeId} - ${firstBreakOutTime}`);
  if (isEndTimeGreaterThanStartTime(storedJob.in_time_1, firstBreakOutTime)) {
    if (storedJob.in_time_2) {
      setDetailedJobRecords(
        setSelectedJobIf2TimesExist(
          detailedJobRecords,
          selectedJob,
          firstBreakOutTime,
          selectedJob.in_time_1,
          selectedJob.out_time_1,
          firstBreakOutTime,
          selectedJob.in_time_2,
          timeId
        )
      );
    } else {
      setDetailedJobRecords(
        setBasicTimeForSelectedJob(
          detailedJobRecords,
          selectedJob,
          firstBreakOutTime,
          timeId
        )
      );
    }
  } else {
    console.log(`${timeId} - ${firstBreakOutTime} is INVALID`);
    setDetailedJobRecords(
      setBasicTimeForSelectedJob(detailedJobRecords, selectedJob, "", timeId)
    );
  }
};

export const setSecondBreakInTime = (
  storedJob: StewardReportDetail_ui,
  selectedJob: StewardReportDetail_ui,
  secondBreakInTime: string,
  timeId: any,
  detailedJobRecords: StewardReportDetail_ui[],
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >
) => {
  console.log(`setting ${timeId} - ${secondBreakInTime}`);
  if (isEndTimeGreaterThanStartTime(storedJob.out_time_3, secondBreakInTime)) {
    setDetailedJobRecords(
      setSelectedJobIfAllTimesExist(
        detailedJobRecords,
        selectedJob,
        secondBreakInTime,
        selectedJob.in_time_1,
        selectedJob.out_time_1,
        selectedJob.out_time_2,
        selectedJob.in_time_2,
        selectedJob.out_time_3,
        secondBreakInTime,
        timeId
      )
    );
  } else {
    console.log(`${timeId} - ${secondBreakInTime} is INVALID`);
    setDetailedJobRecords(
      setBasicTimeForSelectedJob(detailedJobRecords, selectedJob, "", timeId)
    );
  }
};

export const setSecondBreakOutTime = (
  storedJob: StewardReportDetail_ui,
  selectedJob: StewardReportDetail_ui,
  secondBreakOutTime: string,
  timeId: any,
  detailedJobRecords: StewardReportDetail_ui[],
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >
) => {
  const isSecondBreakInTimeValid = isEndTimeGreaterThanStartTime(
    storedJob.in_time_2,
    secondBreakOutTime
  );

  console.log(`setting ${timeId} - ${secondBreakOutTime}`);

  setDetailedJobRecords(
    isSecondBreakInTimeValid
      ? setSelectedJobIfAllTimesExist(
          detailedJobRecords,
          selectedJob,
          secondBreakOutTime,
          selectedJob.in_time_1,
          secondBreakOutTime,
          selectedJob.out_time_2,
          selectedJob.in_time_2,
          secondBreakOutTime,
          selectedJob.in_time_3,
          timeId
        )
      : setBasicTimeForSelectedJob(detailedJobRecords, selectedJob, "", timeId)
  );
};

export const setRegularInTime = (
  storedJob: StewardReportDetail_ui,
  selectedJob: StewardReportDetail_ui,
  regularInTime: string,
  timeId: any,
  detailedJobRecords: StewardReportDetail_ui[],
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >
) => {
  const isRegularInTimeValid = storedJob.in_time_3
    ? isEndTimeGreaterThanStartTime(regularInTime, storedJob.in_time_3)
    : storedJob.in_time_2
    ? isEndTimeGreaterThanStartTime(regularInTime, storedJob.in_time_2)
    : isEndTimeGreaterThanStartTime(regularInTime, storedJob.out_time_1);

  console.log(`setting ${timeId} - ${regularInTime}`);

  setDetailedJobRecords(
    isRegularInTimeValid
      ? setSelectedJobIfAllTimesExist(
          detailedJobRecords,
          selectedJob,
          regularInTime,
          regularInTime,
          selectedJob.out_time_1,
          selectedJob.out_time_2,
          selectedJob.in_time_2,
          selectedJob.out_time_3,
          selectedJob.in_time_3,
          timeId
        )
      : isRegularInTimeValid && storedJob.in_time_2
      ? setSelectedJobIf2TimesExist(
          detailedJobRecords,
          selectedJob,
          regularInTime,
          regularInTime,
          selectedJob.out_time_1,
          selectedJob.out_time_2,
          selectedJob.in_time_2,
          timeId
        )
      : setSelectedJobIf1TimeExists(
          detailedJobRecords,
          selectedJob,
          regularInTime,
          regularInTime,
          selectedJob.out_time_1,
          timeId
        )
  );
};

export const setRegularOutTime = (
  storedJob: StewardReportDetail_ui,
  selectedJob: StewardReportDetail_ui,
  regularOutTime: string,
  timeId: any,
  detailedJobRecords: StewardReportDetail_ui[],
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >
) => {
  const isRegularOutTimeValid = storedJob.in_time_3
    ? isEndTimeGreaterThanStartTime(storedJob.in_time_3, regularOutTime)
    : storedJob.in_time_2
    ? isEndTimeGreaterThanStartTime(storedJob.in_time_2, regularOutTime)
    : isEndTimeGreaterThanStartTime(storedJob.in_time_1, regularOutTime);

  console.log(`setting ${timeId} - ${regularOutTime}`);

  setDetailedJobRecords(
    isRegularOutTimeValid
      ? setSelectedJobIfAllTimesExist(
          detailedJobRecords,
          selectedJob,
          regularOutTime,
          selectedJob.in_time_1,
          regularOutTime,
          selectedJob.out_time_2,
          selectedJob.in_time_2,
          selectedJob.out_time_3,
          selectedJob.in_time_3,
          timeId
        )
      : isRegularOutTimeValid && storedJob.in_time_2
      ? setSelectedJobIf2TimesExist(
          detailedJobRecords,
          selectedJob,
          regularOutTime,
          selectedJob.in_time_1,
          regularOutTime,
          selectedJob.out_time_2,
          selectedJob.in_time_2,
          timeId
        )
      : setSelectedJobIf1TimeExists(
          detailedJobRecords,
          selectedJob,
          regularOutTime,
          storedJob.in_time_1,
          regularOutTime,
          timeId
        )
  );
};
