import type { FC } from 'react';
import { Spinner,Select ,Toast} from '../../lib';
import availServices  from '../../services/availSvcs';
import {  HiCheck } from 'react-icons/hi';
import { member_available_dates }  from '../../services/dtos';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const MemberAvailabilityPage: FC = () => {

  const [availList, setavailList] = useState<Array<member_available_dates>>(null);
  const [loading, setLoading] = useState(false);
  const [updateOk, setupdateOk] = useState(false);

  let navigate = useNavigate();

  const setAvailability = (e : any)  => {
  {

    let availability_date = e.target.id.replace("avail_","");
    let availability_type = e.target.value;

    let svc = new availServices();
     svc.setavailability(availability_date,availability_type).then(()=>{
        setupdateOk(true);
        setLoading(true);

        let svc = new availServices();
       
       
      
    
        svc.getAvailability().then((data)=>
        {
            setavailList(data);
          
            setLoading(false);
          
            
        }
        );
     });
     
  }
}

  if(!availList && !loading)
  {
    setLoading(true);

    let svc = new availServices();
   
   
  

    svc.getAvailability().then((data)=>
    {
        setavailList(data);
      
        setLoading(false);
      
        
    }
    ).catch((e)=>{
      console.log(e);
      setLoading(false);
    if(e.type=="RefreshTokenException" || e.responseStatus.errorCode == "403" || e.responseStatus.errorCode == "401")
    {
      navigate("/login");

    }
    
    })
}

  return (
    <div className="mx-auto flex max-w-4xl flex-col gap-8 dark:text-white">

    
    

   
        <span className="text-2xl font-bold">Update your Availability</span>
        {updateOk &&
      <Toast>
    <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-sm bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
      <HiCheck className="h-5 w-5" />
    </div>
    <div className="ml-3 text-sm font-normal">
      Updates completed.
    </div>
    <Toast.Toggle />
  </Toast>}
       {loading && <Spinner
    color="success"
   
  />}
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
 
                <th scope="col" className="py-3 px-6">
                    Date
                </th>
                <th scope="col" className="py-3 px-6">
                    Availability
                </th>
               
            </tr>
        </thead>
        <tbody>

{availList && availList.length>0 && availList.map(({availability_date,availability_date_display,availability,availability_date_dow}, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
               
                <td className="py-4 px-6">
                {availability_date_dow} {availability_date_display}
                </td>
                <td className="py-4 px-6">
        
 


          <Select id={"avail_"+availability_date} required value={availability} onChange={setAvailability} >
            <option value="Not Specified">Choose</option>
            <option value="All Day">All Day</option>
            <option value="Evenings">Evenings</option>
            <option value="Afternoons">Afternoons</option>
            <option value="Mornings">Mornings</option>
            <option value="Not Available">Not Available</option>
          </Select>

                </td>
                
            </tr>))}
       
        </tbody>
    </table>
    
</div>

      );




};

export default MemberAvailabilityPage;
