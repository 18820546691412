import type { FC } from 'react';

import { useState, useEffect } from 'react';

import { Button, Label, TextInput, Toast, Table } from '../../lib';
import { HiInformationCircle, HiCheck,HiExclamation } from 'react-icons/hi';
import { Alert } from '../../lib';
import { useNavigate, useParams } from "react-router-dom";
import jobOfferServices from '../../services/jobOfferServices';
import { job_offer_ui, member_job_history } from '../../services/dtos';
import { Spinner } from '../../lib';
import apiServices from '../../services/apiServices';

const JobOfferPage: FC = () => {

  const [offer, setJobOffer] = useState<job_offer_ui>(new job_offer_ui());
  const [updateOk, setupdateOK] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [acceptConfirming, setacceptConfirming] = useState<boolean>(false);
  const [declineConfirming, setdeclineConfirming] = useState<boolean>(false);
  const [cntTryLoad, setcntTryLoad] = useState<number>(0);

  const [acceptedComplete, setacceptedComplete] = useState<boolean>(false);

  const { shortCode } = useParams()

  let navigate = useNavigate();

  let svc2 = new apiServices();
  const [baseUrl, setbaseUrl] = useState<string>(svc2.apiUrl());



  const handleResponse = (accepted: boolean) => {

    setloading(true);

    let svc = new jobOfferServices();
    svc.respondToJobOffer(shortCode,  accepted).then(() => {
      setloading(false);
      setupdateOK(true);
      setacceptConfirming(false);
      setdeclineConfirming(false);
      setacceptedComplete(accepted);
      
      

    }).catch((e: any) => {
      console.log(e);
      setacceptConfirming(false);
      setdeclineConfirming(false);
      
      setloading(false);
      setErrorMessage("Error updating your information");

    });



  }

  useEffect(() => {

    if (cntTryLoad>=0 && cntTryLoad<5 && !loading) {
      setloading(true);
      
      let svc = new jobOfferServices();
      setcntTryLoad(cntTryLoad+1);

      svc.getJobOffer(shortCode).then((data) => {
        setJobOffer(data);
        setloading(false);
        setcntTryLoad(-1);




      }).catch((e: any) => {
        setloading(false);
        console.log(e);
        setErrorMessage("Sorry, This job offer has expired or was not found");
        
      })
    }
  });



  return (

    <div className="mx-auto  flex max-w-4xl flex-col gap-8 dark:text-white">

      <span className="text-2xl font-bold">Job  Offer</span>
      {acceptedComplete && <span> <a href={baseUrl+"/oc/" + shortCode}>Add To Calendar:<svg  width="30px" height="30px" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"></path>
</svg></a></span>}
      <Table>
          <Table.Head>
            <Table.HeadCell>

            {offer.job_ID && !loading && !updateOk && !acceptConfirming &&  <Button  onClick={() => { setacceptConfirming(true);setdeclineConfirming(false);}} >Accept</Button>} {offer.job_ID   && !loading && !updateOk && acceptConfirming && <Button onClick={()=> handleResponse(true) } color="success">Confirm Acceptance</Button>}
            </Table.HeadCell>
            <Table.HeadCell>
            {offer.job_ID && !loading && !updateOk && !declineConfirming && <Button onClick={() => { setacceptConfirming(false);setdeclineConfirming(true);}} color="failure">Decline</Button>} {offer.job_ID && !loading && !updateOk && declineConfirming && <Button onClick={()=> handleResponse(false)} color="warning">Confirm Decline</Button>}
            </Table.HeadCell>
          </Table.Head>
        </Table>
      {offer.job_ID &&
      <div>
        <Table>
          <Table.Head>
            <Table.HeadCell>
              Job
            </Table.HeadCell>
            <Table.HeadCell>
              Start Date
            </Table.HeadCell>
            <Table.HeadCell>
              Start Time
            </Table.HeadCell>
            <Table.HeadCell>
              End Date
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell >
                {offer.job_ID} {offer.employer}
              </Table.Cell>
              <Table.Cell>
                {offer.starting_date_display}
              </Table.Cell>
              <Table.Cell>
                {offer.starting_time}
              </Table.Cell>
              <Table.Cell>
                {offer.ending_date_display}
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>

        <Table>
          <Table.Head>
            <Table.HeadCell>
              Skill
            </Table.HeadCell>
            
            <Table.HeadCell>
              Site
            </Table.HeadCell>
            <Table.HeadCell>
              Report To Location
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell >
                {offer.skill}
              </Table.Cell>
              <Table.Cell>
                {offer.site}
              </Table.Cell>
              <Table.Cell>
                {offer.report_To_Location}
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>
        
        <Table>
          <Table.Head>
            <Table.HeadCell>
              Steward
            </Table.HeadCell>
            {offer.local_feature_toggles?.dont_display_show!="Y" && <Table.HeadCell>
              Show
            </Table.HeadCell>}
            <Table.HeadCell>
              Respond By
            </Table.HeadCell>
        
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>
                {offer.steward_Fname} {offer.steward_Lname} {offer.steward_Phone}
              </Table.Cell>
              {offer.local_feature_toggles?.dont_display_show!="Y" && <Table.Cell>
                {offer.show} 
              </Table.Cell>}
              <Table.Cell>
                {offer.respond_by_display}
              </Table.Cell>
             </Table.Row>

          </Table.Body>
        </Table>
        </div>
        }
        
      {loading &&
        <Spinner color="success" />}

        {errorMessage && 
      <Toast>
      <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
        <HiExclamation className="h-5 w-5" />
      </div>
      <div className="ml-3 text-sm font-normal">
        {errorMessage}
      </div>
      <Toast.Toggle />
    </Toast>
  
        }
 
      {updateOk &&
        <Toast>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
            <HiCheck className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">
            Updates completed.
          </div>
          <Toast.Toggle />
        </Toast>}

    </div>


  );

};

export default JobOfferPage;
