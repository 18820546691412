import React, { useState } from "react";
import { StewardReportDetail_ui } from "../../../services/dtos";
import { TIMESHEET_IDS, TIMESHEET_ID_TO_PROPERTY_MAP } from "../../constants";
import { Button } from "../Button";
import { Modal } from "../Modal";

interface MemberDetailsRowProps {
  detailedJobRecords: StewardReportDetail_ui[];
  departments: string[]; // Assuming you have a Department type defined
  alreadySubmitted: boolean;
  handleDeleteMember: (
    id: number,
    detailedJobRecords: StewardReportDetail_ui[],
    setDetailedJobRecords: React.Dispatch<
      React.SetStateAction<StewardReportDetail_ui[]>
    >
  ) => Promise<boolean>;
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >;
  handleTableInputChange: (
    selectedJob: StewardReportDetail_ui,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    timesheetId?: keyof typeof TIMESHEET_IDS
  ) => void;
  setTimeToNow: (
    detailedJob: StewardReportDetail_ui,
    timeField: string
  ) => void;
}

export const MemberDetailRows = ({
  detailedJobRecords,
  departments,
  alreadySubmitted,
  handleTableInputChange,
  handleDeleteMember,
  setTimeToNow,
  setDetailedJobRecords,
}: MemberDetailsRowProps) => {
  const [showCancelConfirmModal, setShowCancelConfirmModal] =
    useState<boolean>(false);
  const [memberToDelete, setMemberToDelete] = useState<{
    memberName: string;
    jobId: number;
  }>();
  const getDepartmentSelector = (detailedJob: StewardReportDetail_ui) => {
    return (
      <select
        data-te-select-init
        id={TIMESHEET_IDS.DEPARTMENT}
        disabled={alreadySubmitted}
        onChange={(e) => handleTableInputChange(detailedJob, e)}
        value={detailedJob.department}
      >
        {departments.map((dept) => {
          return (
            <option
              key={dept}
              // selected={dept === detailedJob.department}
              value={dept}
            >
              {dept}
            </option>
          );
        })}
      </select>
    );
  };

  const GenericTimeField = (
    detailedJob: StewardReportDetail_ui,
    timesheetId: keyof typeof TIMESHEET_IDS,
    index: number
  ) => {
    return (
      <td
        key={
          "gtf-" +
          detailedJob.id +
          "-" +
          detailedJob.job_ID +
          "-" +
          detailedJob.unique_member_id +
          index
        }
      >
        <div className="flex">
          <input
            id={TIMESHEET_IDS[timesheetId]}
            disabled={alreadySubmitted}
            value={
              detailedJob[
                TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS[timesheetId]]
              ]
            }
            onChange={(e) => {
              console.log(e.target.value);
              handleTableInputChange(detailedJob, e, timesheetId);
            }}
            type="time"
          />
          <button
            onClick={() => setTimeToNow(detailedJob, timesheetId)}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm p-1.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            NOW
          </button>
        </div>
      </td>
    );
  };
  const sortTimesheetIds = (ids: string[]) => {
    const order = [
      "IN_TIME_1",
      "OUT_TIME_2",
      "IN_TIME_2",
      "OUT_TIME_3",
      "IN_TIME_3",
      "OUT_TIME_1",
    ];
    return ids.sort((a, b) => {
      const indexA = order.indexOf(a);
      const indexB = order.indexOf(b);
      return indexA - indexB;
    });
  };
  return (
    <>
      {detailedJobRecords.map(
        (detailedJob: StewardReportDetail_ui, index: number) => (
          <tr
            className="table-data-rows"
            key={
              detailedJob.id +
              detailedJob.job_ID +
              detailedJob.unique_member_id +
              index
            }
          >
            <td>
              <button
                type="button"
                className="ml-2 text-white bg-red-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-1.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => {
                  setShowCancelConfirmModal(true);
                  setMemberToDelete({
                    jobId: detailedJob.id,
                    memberName: detailedJob.member_name,
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </td>
            <td>{detailedJob.member_name}</td>
            <td>
              {departments &&
                departments.length > 0 &&
                getDepartmentSelector(detailedJob)}
            </td>
            <td>
              <input
                id={TIMESHEET_IDS.RATE}
                onChange={(e) => handleTableInputChange(detailedJob, e)}
                type="number"
                disabled={alreadySubmitted}
                value={detailedJob.hrly_rate}
                min={0}
              />
            </td>
            {sortTimesheetIds(
              Object.keys(TIMESHEET_IDS).filter((timesheetId) =>
                timesheetId.includes("_TIME_")
              )
            ).map((timesheetId, index) => {
              return GenericTimeField(
                detailedJob,
                timesheetId as keyof typeof TIMESHEET_IDS,
                index
              );
            })}
            {/* OTHER RATE */}
            <td>
              <input
                id={TIMESHEET_IDS.OTHER_RATE}
                onChange={(e) => handleTableInputChange(detailedJob, e)}
                type="number"
                disabled={alreadySubmitted}
                value={detailedJob.rate_other}
                min={0}
              />
            </td>
            {/* OTHER QUANTITY */}
            <td>
              <input
                id={TIMESHEET_IDS.OTHER_QUANTITY}
                onChange={(e) => handleTableInputChange(detailedJob, e)}
                type="number"
                disabled={alreadySubmitted}
                value={detailedJob.qty_other}
                min={0}
              />
            </td>
            {/* OTHER TOTAL */}
            <td>
              <input
                id="other-total"
                type="number"
                disabled={alreadySubmitted}
                defaultValue={detailedJob.qty_other * detailedJob.rate_other}
              />
            </td>
            <td
              className={
                detailedJob.qty_hrs_1x +
                  detailedJob.qty_hrs_1_5x +
                  detailedJob.qty_hrs_2_0x !==
                detailedJob.qty_hrs_ttl
                  ? "error"
                  : "valid"
              }
            >
              <input
                id={TIMESHEET_IDS.ONE_X_TIME}
                type={"number"}
                step={"0.01"}
                onChange={(e) => handleTableInputChange(detailedJob, e)}
                min={"0"}
                max={"24"}
                disabled={alreadySubmitted}
                value={detailedJob.qty_hrs_1x}
              />
            </td>
            <td
              className={
                detailedJob.qty_hrs_1x +
                  detailedJob.qty_hrs_1_5x +
                  detailedJob.qty_hrs_2_0x !==
                detailedJob.qty_hrs_ttl
                  ? "error"
                  : "valid"
              }
            >
              <input
                disabled={alreadySubmitted}
                id={TIMESHEET_IDS.ONE_HALF_X_TIME}
                type={"number"}
                onChange={(e) => handleTableInputChange(detailedJob, e)}
                min={"0"}
                max={"24"}
                step={"0.01"}
                value={detailedJob.qty_hrs_1_5x}
              />
            </td>
            <td
              className={
                detailedJob.qty_hrs_1x +
                  detailedJob.qty_hrs_1_5x +
                  detailedJob.qty_hrs_2_0x !==
                detailedJob.qty_hrs_ttl
                  ? "error"
                  : "valid"
              }
            >
              <input
                disabled={alreadySubmitted}
                id={TIMESHEET_IDS.TWO_X_TIME}
                type={"number"}
                onChange={(e) => handleTableInputChange(detailedJob, e)}
                min={"0"}
                max={"24"}
                step={"0.01"}
                value={detailedJob.qty_hrs_2_0x}
              />
            </td>
            <td>{detailedJob.qty_hrs_ttl}</td>
            <td>
              {/* COMMENTS */}
              <textarea
                id={TIMESHEET_IDS.COMMENT}
                value={detailedJob.comment}
                disabled={alreadySubmitted}
                onChange={(e) => handleTableInputChange(detailedJob, e)}
              />
            </td>
          </tr>
        )
      )}
      {showCancelConfirmModal && Object.keys(memberToDelete).length > 0 && (
        <Modal
          show={showCancelConfirmModal}
          onClose={() => setShowCancelConfirmModal(false)}
        >
          <Modal.Header>Are you sure?</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                This will delete {memberToDelete.memberName}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "red" }}
              onClick={async () => {
                const deleted = await handleDeleteMember(
                  memberToDelete.jobId,
                  detailedJobRecords,
                  setDetailedJobRecords
                );
                if (deleted) {
                  setShowCancelConfirmModal(false);
                } else {
                  setShowCancelConfirmModal(true);
                }
              }}
            >
              Delete
            </Button>
            <Button
              color="gray"
              onClick={() => setShowCancelConfirmModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
