import apiServices from "./apiServices";
import {
  addMemberToStewReport,
  getDepartments,
  getJobsForSteward,
  getmemberjobHistReq,
  getStewDetailRecordsForJob,
  massTextFromStewReport,
  removeMemberFromStewReport,
  saveStewDetailRecs,
  searchMember,
  StewardReportDetail_ui,
} from "./dtos";

class jobServices {
  baseSvc = new apiServices();
  client = this.baseSvc.client;

  constructor() {}

  getJobHistory_older() {
    const request = new getmemberjobHistReq();
    request.skip = 10;
    request.take = 300;

    return this.client.get(request);
  }

  getJobHistory() {
    const request = new getmemberjobHistReq();
    request.skip = 0;
    request.take = 10;

    return this.client.get(request);
  }

  getJobsForSteward() {
    const request = new getJobsForSteward();
    return this.client.get(request);
  }

  getStewardDetailedRecordsForJob(jobId: string, reportDate: string) {
    const request = new getStewDetailRecordsForJob();
    request.job_id = jobId;
    request.report_date = reportDate;
    return this.client.get(request);
  }

  getDepartments() {
    const request = new getDepartments();
    return this.client.get(request);
  }

  saveDetailedJobRecords(
    detailedJobRecords: StewardReportDetail_ui[],
    finalSubmit?: boolean
  ) {
    console.log("saving actual - ", detailedJobRecords);
    const request = new saveStewDetailRecs();
    request.detail_records = detailedJobRecords;
    if (finalSubmit) {
      request.final_submit = finalSubmit;
    }
    return this.client.put(request);
  }

  searchMember(searchTerm: string) {
    const request = new searchMember();
    request.search_term = searchTerm;
    return this.client.get(request);
  }

  addMember(
    jobId: string,
    stewardReportHeaderId: number,
    uniqueMemberId: string
  ) {
    const request = new addMemberToStewReport();
    request.job_ID = jobId;
    request.stewardReportHeaderId = stewardReportHeaderId;
    request.unique_member_id = uniqueMemberId;
    return this.client.post(request);
  }

  removeMember(id: number) {
    const request = new removeMemberFromStewReport();
    request.id = id;
    console.log("Deleting " + id);
    return this.client.delete(request);
  }

  massTextFromStewReport(
    jobId: string,
    smsMessage: string,
    uniqueMemberIds: string[]
  ) {
    const request = new massTextFromStewReport();
    request.job_ID = jobId;
    request.sms_message = smsMessage;
    request.unique_member_ids = uniqueMemberIds;
    return this.client.post(request);
  }
}

export default jobServices;
