//import { createContext, FC, useContext} from 'react';
import {  FC } from 'react';

import { Suspense, useRef, useState,useEffect } from 'react';
//import {HiMenuAlt1} from 'react-icons/hi';
import {  Route, Routes } from 'react-router-dom';
import {  Navbar, Spinner ,DarkThemeToggle} from '../lib';
import LoginPage from './pages/LoginPage';

import apiServices  from './../services/apiServices';

import RegisterPage from './pages/RegisterPage';
import JobHistoryPage from './pages/JobHistoryPage';
import MemberInfoPage from './pages/MemberInfoPage';
import MemberAvailabilityPage from './pages/MemberAvailabilityPage';
import FeedbackPage from './pages/FeedbackPage';
import { useNavigate } from "react-router-dom";
import JobOfferPage from './pages/JobOfferPage';
import { TimesheetPage } from './pages/TimesheetPage';
//import { useNavbarContext } from '../lib/components/Navbar/NavbarContext';
export const Root: FC = () => {

  //const [collapsed, setCollapsed] = useState(true);
  const [loggedIn,setLoggedIn] = useState(false);
  const [navbarCollapse,setnavbarCollapse] = useState(false);
 const [isInStewardRole,setisInStewardRole] = useState<boolean>(false);


  //const context = createContext({navBorOpen:navbarCollapse});

  useEffect(()=>
  {
    let svc = new apiServices();
    setLoggedIn(svc.isloggedin());

    setisInStewardRole(svc.hasHole("Admin"));

  }
  );

  const mainRef = useRef<HTMLDivElement>(null);

  //document.documentElement.classList.remove('dark');
  
  let navigate = useNavigate();

  const handleNavBarClick = () => {
    setnavbarCollapse(!navbarCollapse);
  };

  let logout = function () {
    let svc = new apiServices();
    setLoggedIn(false);

    svc.logout();
  
    navigate("/login");

    
  }


  
  useEffect(() => {
    let svc = new apiServices();
    setLoggedIn(svc.isloggedin);
  });



  

  //setLoggedIn(!svc.isloggedin());


  return (
    <div className="flex h-screen w-full flex-col  bg-white dark:bg-gray-700 overflow-hidden">

      <Navbar  className="border-b" fluid>

      <Navbar.Toggle  />
    
      <DarkThemeToggle />
    
      <Navbar.Collapse>
    


    {!loggedIn  && <Navbar.Link
      href="/login"
      active={true}
    > 
      Login
    </Navbar.Link>}
    {!loggedIn  && <Navbar.Link
      href="/register"
      active={true}
    > 
      Register
    </Navbar.Link>}


    {loggedIn  && <Navbar.Link href="/avail" onClick={handleNavBarClick}>
      My Availability
    </Navbar.Link>}

          {loggedIn && <Navbar.Link href="/timesheet">Timesheet</Navbar.Link>}

    {loggedIn  && <Navbar.Link href="/jobhist">
      Job History
    </Navbar.Link>}

    {loggedIn  && isInStewardRole && <Navbar.Link href="/timesheet">
      Steward Reports
    </Navbar.Link>}


    {loggedIn  && <Navbar.Link href="/feedback">
      Feedback
    </Navbar.Link>}

    {loggedIn  && <Navbar.Link href="/login" onClick={logout}>
      Logout
    </Navbar.Link>}

  
  </Navbar.Collapse>
  
  
</Navbar>

<div className="flex h-screen w-full flex-col overflow-hidden">
       
        <main className="flex-1 overflow-auto p-4" ref={mainRef}>
          <Suspense
            fallback={
              <div className="flex h-full items-center justify-center">
                <Spinner />
              </div>
            }
          >
            <Routes>
           

              <Route key="/login" path="/login" element={<LoginPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
              <Route key="/register" path="/register"    element={<RegisterPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
              { <Route key="/info" path="/info"    element={<MemberInfoPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />}
              { <Route key="/jobhist" path="/jobhist"    element={<JobHistoryPage/>} />}
              { <Route key="/timesheet" path="/timesheet"    element={<TimesheetPage/>} />}
              { <Route key="/avail" path="/avail"    element={<MemberAvailabilityPage/>} />}
              { <Route key="/feedback" path="/feedback"    element={<FeedbackPage/>} />}
              { <Route key="/offer" path="/offer/:shortCode"    element={<JobOfferPage/>} />}
              { <Route key="/o" path="/o/:shortCode"    element={<JobOfferPage/>} />}
            
              { <Route key="/" path="/"    element={<MemberInfoPage isLoggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />}
              
            
            </Routes>
          </Suspense>
        </main>
      </div>
     </div>
  );
 
 };
