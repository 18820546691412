import { StewardReportDetail_ui } from "../services/dtos";

type TimesheetIdToPropertyMap = {
  [key: string]: keyof StewardReportDetail_ui;
};

export enum TIMESHEET_IDS {
  IN_TIME_1 = "in-time-1",
  IN_TIME_2 = "in-time-2",
  IN_TIME_3 = "in-time-3",
  OUT_TIME_1 = "out-time-1",
  OUT_TIME_2 = "out-time-2",
  OUT_TIME_3 = "out-time-3",
  ONE_X_TIME = "1_0x-time",
  ONE_HALF_X_TIME = "1_5x-time",
  TWO_X_TIME = "2_x-time",
  COMMENT = "comment",
  RATE = "rate",
  OTHER_RATE = "other-rate",
  OTHER_QUANTITY = "other-quantity",
  DEPARTMENT = "department"
}

export const TIMESHEET_ID_TO_PROPERTY_MAP: TimesheetIdToPropertyMap = {
  [TIMESHEET_IDS.IN_TIME_1]: "in_time_1",
  [TIMESHEET_IDS.IN_TIME_2]: "in_time_2",
  [TIMESHEET_IDS.IN_TIME_3]: "in_time_3",
  [TIMESHEET_IDS.OUT_TIME_1]: "out_time_1",
  [TIMESHEET_IDS.OUT_TIME_2]: "out_time_2",
  [TIMESHEET_IDS.OUT_TIME_3]: "out_time_3",
  [TIMESHEET_IDS.COMMENT]: "comment",
  [TIMESHEET_IDS.RATE] : "hrly_rate",
  [TIMESHEET_IDS.OTHER_RATE]: "rate_other",
  [TIMESHEET_IDS.OTHER_QUANTITY]: "qty_other",
  [TIMESHEET_IDS.DEPARTMENT]: "department"

}
