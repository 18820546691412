/* Options:
Date: 2023-09-11 14:41:45
Version: 6.21
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:8443

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IGet
{
}

export class Skills
{
    public skills_Id: number;
    public skill: string;
    public archive: boolean;
    public key_skill: boolean;
    public skillDepartment: string;

    public constructor(init?: Partial<Skills>) { (Object as any).assign(this, init); }
}

export class member_job_history
{
    public pkey: string;
    public unique_member_id: string;
    public dispatch_update_date?: string;
    public dispatch_Ident: number;
    public line_Number: number;
    public date_Wrote?: string;
    public processor: string;
    public dispatcher: string;
    public wage_Code: string;
    public starting_time: string;
    public starting_Date?: string;
    public date_Off_OWL?: string;
    public ending_Date?: string;
    public called_By: string;
    public posted: boolean;
    public void: boolean;
    public personVoid: string;
    public date_Time_Void?: string;
    public area2: string;
    public uploaded: boolean;
    public report_To: string;
    public prev_Employer: number;
    public prev_Employed_Date?: string;
    public vac_rate: string;
    public dress_Code: string;
    public dress_Code2: string;
    public finish_Time: string;
    public booked: boolean;
    public locked: boolean;
    public lockedBy: string;
    public hourly_Rate: number;
    public show_Rate: number;
    public doNotFill: boolean;
    public job_Detail_id: number;
    public hold: boolean;
    public employer: string;
    public id: number;
    public job_ID: string;
    public employer_ID: number;
    public job_Description: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public primary_Phone: string;
    public fax_Phone: string;
    public foreperson: string;
    public contact: string;
    public start_Date?: string;
    public end_Date?: string;
    public area: string;
    public active: boolean;
    public atpA_ID: string;
    public contract_Expires?: string;
    public county: string;
    public pR_Co_ID: number;
    public pR_Phone: string;
    public pR_Fax: string;
    public call_Placed_By: string;
    public show_Id: number;
    public site: string;
    public job_Notes: string;
    public report_To_Location: string;
    public report_To_Person: string;
    public def_Wage_Code: string;
    public external_Empl_JobID: string;
    public creator: string;
    public createDate?: string;
    public updater: string;
    public updateDate?: string;
    public duesType: string;
    public steward_Rpt_Required: boolean;
    public emailed: boolean;
    public printed: boolean;
    public email: string;
    public vacation: string;
    public pension: string;
    public training: number;
    public annuity: string;
    public hw: string;
    public skill: string;
    public steward_Fname: string;
    public steward_Lname: string;
    public steward_Phone: string;
    public steward_unique_member_id: string;
    public notifyViaSms: boolean;
    public confirmViaSms: boolean;
    public memberResponseBy?: string;
    public confirmRespondBy?: string;
    public confirmed: boolean;
    public show: string;
    // @Ignore()
    public ending_date_display: string;

    // @Ignore()
    public respond_by_display: string;

    // @Ignore()
    public starting_date_display: string;

    public last_windues_update?: string;

    public constructor(init?: Partial<member_job_history>) { (Object as any).assign(this, init); }
}

export class localmember
{
    public contact_email: string;
    public login_email: string;
    public local_union_id: number;
    public home_phone: string;
    public cell_phone: string;
    public address_line_1: string;
    public address_line_2: string;
    public address_city: string;
    public address_state: string;
    public address_postal_code: string;
    public invite_code: string;
    public unique_member_id: string;
    public verification_code: string;
    public auth_id: number;
    // @StringLength(2147483647)
    public feedback: string;

    public registered_date?: string;
    public membership_status: string;
    public first_name: string;
    public last_name: string;
    public active: boolean;
    // @Ignore()
    public login_active: boolean;

    public constructor(init?: Partial<localmember>) { (Object as any).assign(this, init); }
}

export interface IUserAuth
{
    id: number;
    primaryEmail: string;
    salt: string;
    passwordHash: string;
    digestHa1Hash: string;
    roles: string[];
    permissions: string[];
    refId?: number;
    refIdStr: string;
    invalidLoginAttempts: number;
    lastLoginAttempt?: string;
    lockedDate?: string;
    createdDate: string;
    modifiedDate: string;
}

export class member_available_dates
{
    public local_id: number;
    public unique_member_id: string;
    public availability_date: string;
    // @Ignore()
    public availability_date_display: string;

    // @Ignore()
    public availability_date_dow: string;

    public pkey: string;
    public availability: string;
    public last_sent_to_windues?: string;
    public last_updated: string;
    public ip_address: string;

    public constructor(init?: Partial<member_available_dates>) { (Object as any).assign(this, init); }
}

export class member_bio_changes
{
    public pkey: string;
    public update_date: string;
    public ip_address: string;
    public sent_to_windues_date?: string;
    public local_id: number;
    public unique_member_id: string;
    public address_line_1: string;
    public address_line_2: string;
    public address_city: string;
    public address_state: string;
    public address_postal_code: string;
    public usps_address_line_1: string;
    public usps_address_line_2: string;
    public usps_address_city: string;
    public usps_address_state: string;
    public usps_address_postal_code: string;
    public contact_email: string;
    public portal_login_email: string;
    public home_phone: string;
    public cell_phone: string;

    public constructor(init?: Partial<member_bio_changes>) { (Object as any).assign(this, init); }
}

export class job
{
    public employer: string;
    public job_ID: string;
    public employer_ID: number;
    public job_Description: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public primary_Phone: string;
    public fax_Phone: string;
    public foreperson: string;
    public contact: string;
    public start_Date?: string;
    public end_Date?: string;
    public area: string;
    public active: boolean;
    public contract_Expires?: string;
    public county: string;
    public pR_Co_ID: number;
    public pR_Phone: string;
    public pR_Fax: string;
    public call_Placed_By: string;
    public show_Id: number;
    public site: string;
    public job_Notes: string;
    public report_To_Location: string;
    public report_To_Person: string;
    public def_Wage_Code: string;
    public external_Empl_JobID: string;
    public updateDate?: string;
    public duesType: string;
    public steward_Rpt_Required: boolean;
    public emailed: boolean;
    public printed: boolean;
    public email: string;
    public vacation: string;
    public pension: string;
    public training: number;
    public annuity: string;
    public hw: string;
    public steward_Fname: string;
    public steward_Lname: string;
    public steward_Phone: string;
    public steward_unique_member_id: string;
    public show: string;

    public constructor(init?: Partial<job>) { (Object as any).assign(this, init); }
}

export class TextLog
{
    public id: number;
    public date_sent: string;
    public unique_member_id: string;
    public job_ID: string;
    public dispatch_Ident: number;
    public sms_number: string;
    public twilio_id: string;
    public text_sent: string;

    public constructor(init?: Partial<TextLog>) { (Object as any).assign(this, init); }
}

export class TextLogLocalUnion extends TextLog
{
    public id: number;

    public constructor(init?: Partial<TextLogLocalUnion>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export class localunion
{
    public id: number;
    public active: boolean;
    public auth_id: number;
    public local_name: string;
    public email_address: string;
    // @Ignore()
    public stripe_secret_key: string;

    public stripe_secret_key_enc: string;
    // @Ignore()
    public portal_api_key: string;

    public portal_api_key_enc: string;
    public stripe_publishable_key: string;
    public created_date: string;
    public our_stripe_customer_id: string;
    public our_stripe_customer_card_id: string;
    public revenue_rate_percent: number;
    public revenue_rate_per_trans: number;
    public api_key: string;
    public api_base_url: string;
    public recoup_fees_rate: number;
    public recoup_fees_per_trans: number;
    public remind_payment_due_days_in_advance: number;
    public last_fees_swept?: string;
    public codes_must_pay_first: string[];
    public codes_block_any_payment: string[];
    public codes_hide: string[];
    public allow_autopay: boolean;
    public local_feature_toggles: { [index: string]: string; };

    public constructor(init?: Partial<localunion>) { (Object as any).assign(this, init); }
}

export class success_response
{
    public message: string;

    public constructor(init?: Partial<success_response>) { (Object as any).assign(this, init); }
}

export class createLocalUnionResp
{
    public id: number;
    public apiKey: string;

    public constructor(init?: Partial<createLocalUnionResp>) { (Object as any).assign(this, init); }
}

export class job_offer_ui
{
    public report_To_Location: string;
    public report_To_Person: string;
    public site: string;
    public steward_Fname: string;
    public steward_Lname: string;
    public steward_Phone: string;
    public show: string;
    public skill: string;
    public ending_Date?: string;
    public memberResponseBy?: string;
    public starting_Date?: string;
    public employer: string;
    public job_ID: string;
    public starting_time: string;
    // @Ignore()
    public ending_date_display: string;

    // @Ignore()
    public respond_by_display: string;

    // @Ignore()
    public starting_date_display: string;

    // @Ignore()
    public local_feature_toggles: { [index: string]: string; };

    public constructor(init?: Partial<job_offer_ui>) { (Object as any).assign(this, init); }
}

export class updateResp
{
    public cntUpdated: number;
    public cntInserted: number;
    public cntDeleted: number;
    public message: string;
    public cntError: number;

    public constructor(init?: Partial<updateResp>) { (Object as any).assign(this, init); }
}

export class MultiJobOffer
{
    // @StringLength(50)
    public shortCode: string;

    public superceded: boolean;
    public dateNotified?: string;
    public memberResponseBy?: string;
    // @StringLength(50)
    public job_id: string;

    public cnt_offers: number;
    public skills_id: number;
    // @StringLength(50)
    public skill: string;

    public notified: boolean;

    public constructor(init?: Partial<MultiJobOffer>) { (Object as any).assign(this, init); }
}

export class ConsolidatedAddress
{
    public address_line_1: string;
    public address_line_2: string;
    public address_city: string;
    public address_state: string;
    public address_postal_code: string;

    public constructor(init?: Partial<ConsolidatedAddress>) { (Object as any).assign(this, init); }
}

export class getmemberjobHistResp
{
    public job_history: member_job_history[];

    public constructor(init?: Partial<getmemberjobHistResp>) { (Object as any).assign(this, init); }
}

export class local_member_ui
{
    public auth_id: number;
    public contact_email: string;
    public login_email: string;
    public local_union_id: number;
    public cell_phone: string;
    public home_phone: string;
    public unique_member_id: string;
    public address_line_1: string;
    public address_line_2: string;
    public address_city: string;
    public address_state: string;
    public address_postal_code: string;
    public registered_date?: string;
    public membership_status: string;
    public first_name: string;
    public last_name: string;
    // @Ignore()
    public local_name: string;

    // @Ignore()
    public local_feature_toggles: { [index: string]: string; };

    public active: boolean;
    // @Ignore()
    public login_active: boolean;

    public constructor(init?: Partial<local_member_ui>) { (Object as any).assign(this, init); }
}

export class massTextFromStewReportResponse
{
    public cntSent: number;
    public cntNoSMS: number;
    public cntError: number;

    public constructor(init?: Partial<massTextFromStewReportResponse>) { (Object as any).assign(this, init); }
}

export class status_message
{
    public message: string;

    public constructor(init?: Partial<status_message>) { (Object as any).assign(this, init); }
}

export class StewardReportDetail_ui
{
    public id: number;
    public stewardReportHeaderId: number;
    // @Ignore()
    public submitted_from_portal_date?: string;

    public job_ID: string;
    // @Ignore()
    public member_name: string;

    public unique_member_id: string;
    public department: string;
    public hrly_rate: number;
    public in_time_1: string;
    public out_time_1: string;
    public in_time_2: string;
    public out_time_2: string;
    public in_time_3: string;
    public out_time_3: string;
    public qty_hrs_1x: number;
    public qty_hrs_1_5x: number;
    public qty_hrs_2_0x: number;
    public qty_hrs_ttl: number;
    public qty_other: number;
    public rate_other: number;
    public comment: string;

    public constructor(init?: Partial<StewardReportDetail_ui>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @Route("/admin/unlock_and_reset_password", "PUT")
export class modifyUser implements IReturn<IUserAuth[]>
{
    public email: string;
    public new_password: string;

    public constructor(init?: Partial<modifyUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'modifyUser'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new Array<IUserAuth>(); }
}

export class pruneDataRequest implements IReturnVoid
{

    public constructor(init?: Partial<pruneDataRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'pruneDataRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/admin/keepalive", "GET")
export class KeepAliveRequest implements IReturnVoid
{

    public constructor(init?: Partial<KeepAliveRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KeepAliveRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/localunion/label", "GET")
export class getLocalUnionName implements IReturn<string>
{

    public constructor(init?: Partial<getLocalUnionName>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getLocalUnionName'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return ''; }
}

// @Route("/localunion", "GET")
export class getMyUnionReq implements IReturn<localunion>
{

    public constructor(init?: Partial<getMyUnionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getMyUnionReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new localunion(); }
}

// @Route("/localunion/listall", "GET")
export class getAllLocalUnions implements IReturn<localunion[]>
{

    public constructor(init?: Partial<getAllLocalUnions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getAllLocalUnions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<localunion>(); }
}

export class getUnionReq implements IReturn<localunion>
{
    public email: string;

    public constructor(init?: Partial<getUnionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getUnionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new localunion(); }
}

// @Route("/localunion/genapikey", "GET")
export class regenApiKeyRequest implements IReturn<success_response>
{
    public local_union_email_address: string;

    public constructor(init?: Partial<regenApiKeyRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'regenApiKeyRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new success_response(); }
}

// @Route("/localunion", "PUT")
export class updateLocalUnionReq implements IReturn<localunion>
{
    public active_Y_N: string;
    public local_id: number;
    public local_name: string;
    public our_fee_rate_percent: string;
    public our_fee_rate_per_trans: string;
    public email_address: string;
    public stripe_secret_key: string;
    public stripe_publishable_key: string;
    public our_stripe_customer_id: string;
    public our_stripe_customer_card_id: string;
    public lunion_recoup_fees_per_trans: string;
    public lunion_recoup_percent: string;

    public constructor(init?: Partial<updateLocalUnionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'updateLocalUnionReq'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new localunion(); }
}

// @Route("/localunion", "POST")
export class createLocalUnionReq implements IReturn<createLocalUnionResp>
{
    public local_name: string;
    public our_fee_rate_percent: number;
    public our_fee_rate_per_trans: number;
    public email_address: string;
    public stripe_secret_key: string;
    public stripe_publishable_key: string;
    public our_stripe_customer_id: string;
    public our_stripe_customer_card_id: string;
    public lunion_recoup_fees_per_trans: number;
    public lunion_recoup_percent: number;

    public constructor(init?: Partial<createLocalUnionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'createLocalUnionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new createLocalUnionResp(); }
}

// @Route("/oc/{shortCode}", "GET")
export class GetJobOfferCalFile
{
    public shortCode: string;

    public constructor(init?: Partial<GetJobOfferCalFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetJobOfferCalFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/jobOffer/{shortCode}", "GET")
export class GetJobOfferFromShortCode implements IReturn<job_offer_ui>
{
    public shortCode: string;

    public constructor(init?: Partial<GetJobOfferFromShortCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetJobOfferFromShortCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new job_offer_ui(); }
}

// @Route("/jobOffer/multi", "PUT")
export class MultiJobOfferResponse implements IReturn<updateResp>
{
    // @StringLength(50)
    public multiJobOffer_shortcode: string;

    // @StringLength(200)
    public pkey: string;

    // @StringLength(50)
    public shortCode: string;

    public accepted: boolean;
    public superceded: boolean;
    public dtViewed?: string;
    public dateNotified?: string;
    public notifiedSid: string;
    public dateResponded?: string;
    public dateResponseAcknowledged?: string;
    public smsAckSid: string;
    public dateDispatchUpdated?: string;
    public memberResponseBy?: string;
    // @StringLength(50)
    public job_id: string;

    // @StringLength(50)
    public unique_member_id: string;

    public dispatch_Ident: number;
    public line_Number: number;
    public notified: boolean;

    public constructor(init?: Partial<MultiJobOfferResponse>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MultiJobOfferResponse'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/jobOffer", "PUT")
export class JobOfferState implements IReturn<updateResp>
{
    public shortCode: string;
    public accepted: boolean;
    public superceded: boolean;
    public dtViewed?: string;
    public dateNotified?: string;
    public notifiedSid: string;
    public dateResponded?: string;
    public dateResponseAcknowledged?: string;
    public smsAckSid: string;
    public dateDispatchUpdated?: string;
    public memberResponseBy?: string;
    public job_id: string;
    public unique_member_id: string;
    public dispatch_Ident: number;
    public line_Number: number;
    public notified: boolean;
    public isConfirmation: boolean;

    public constructor(init?: Partial<JobOfferState>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'JobOfferState'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

export class ackJobOffer implements IReturnVoid
{
    public offer: JobOfferState;

    public constructor(init?: Partial<ackJobOffer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ackJobOffer'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/cli/forceclitoupdatedata")
export class forceCLIUpdate implements IReturn<boolean>
{

    public constructor(init?: Partial<forceCLIUpdate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'forceCLIUpdate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return false; }
}

// @Route("/jobOfferNotify", "PATCH")
export class checkToNotifyJobOffer implements IReturn<updateResp>
{
    public dispatch_Ident: number;

    public constructor(init?: Partial<checkToNotifyJobOffer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'checkToNotifyJobOffer'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/cli/availability_changes", "GET")
export class getAvailabilityChangeRequests implements IReturn<member_available_dates[]>
{

    public constructor(init?: Partial<getAvailabilityChangeRequests>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getAvailabilityChangeRequests'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<member_available_dates>(); }
}

// @Route("/cli/bio_change", "GET")
export class getMemberBioChangeReq implements IReturn<member_bio_changes[]>
{

    public constructor(init?: Partial<getMemberBioChangeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getMemberBioChangeReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<member_bio_changes>(); }
}

// @Route("/cli/removeMembersDispatch", "PUT")
export class removedMembersDispatch implements IReturnVoid
{
    public dispatch_Ident: number;

    public constructor(init?: Partial<removedMembersDispatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'removedMembersDispatch'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/cli/getDispatchResponses", "GET")
export class getDispatchResponses implements IReturn<JobOfferState[]>
{
    public hours_back: number;

    public constructor(init?: Partial<getDispatchResponses>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getDispatchResponses'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<JobOfferState>(); }
}

// @Route("/massTextRealtime", "POST")
export class MassTextRealtime implements IReturn<updateResp>
{
    public job_ID: string;

    public constructor(init?: Partial<MassTextRealtime>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MassTextRealtime'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/cli/massText", "POST")
export class MassTextRequest implements IReturn<string>
{
    public id: number;
    public requested_date?: string;
    public sent_date?: string;
    public unique_member_id: string;
    public dispatch_Ident: number;
    public message: string;
    public job_ID: string;
    public username: string;
    public server_response: string;
    public include_member: boolean;
    public trycount: number;

    public constructor(init?: Partial<MassTextRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MassTextRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/cli/deleteJobHist", "DELETE")
export class deleteJobHistReq implements IReturn<updateResp>
{
    public dispatch_Idents: number[];

    public constructor(init?: Partial<deleteJobHistReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'deleteJobHistReq'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/jobOffer/sendsingle", "POST")
export class sendJobOfferSingle implements IReturn<updateResp>
{
    public dispatch_Ident: number;

    public constructor(init?: Partial<sendJobOfferSingle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'sendJobOfferSingle'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/cli/skills", "PUT")
export class updateSkillsReq implements IReturn<updateResp>
{
    public skills: Skills[];

    public constructor(init?: Partial<updateSkillsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'updateSkillsReq'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/cli/updatejhist", "PUT")
export class updateJobHistRequest implements IReturn<updateResp>
{
    public job_histories: member_job_history[];

    public constructor(init?: Partial<updateJobHistRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'updateJobHistRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/cli/member", "PUT")
export class updateMemberRequest implements IReturn<updateResp>
{
    public members: localmember[];

    public constructor(init?: Partial<updateMemberRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'updateMemberRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/testdate", "POST")
export class testdate implements IReturn<updateResp>
{
    public your_date: string;

    public constructor(init?: Partial<testdate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'testdate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/jobOffer/member", "PUT")
export class addOrDeleteMemberFromJobOffer implements IReturn<updateResp>
{
    public delete: boolean;
    public unique_member_id: string;
    public multiJobOffer_shortcode: string;

    public constructor(init?: Partial<addOrDeleteMemberFromJobOffer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'addOrDeleteMemberFromJobOffer'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/jobOffer/sendmulti", "POST")
export class sendJobOfferMulti implements IReturn<updateResp>
{
    public shortCode: string;
    public respond_by?: string;
    public dispatcher: string;

    public constructor(init?: Partial<sendJobOfferMulti>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'sendJobOfferMulti'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/jobOffer/startmulti", "POST")
export class startJobOfferMulti implements IReturn<MultiJobOffer>
{
    public job_id: string;
    public skills_id: number;
    public cnt_positions: number;

    public constructor(init?: Partial<startJobOfferMulti>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'startJobOfferMulti'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MultiJobOffer(); }
}

// @Route("/job", "PUT")
export class updateJobReq implements IReturn<updateResp>
{
    public jobs: job[];

    public constructor(init?: Partial<updateJobReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'updateJobReq'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/admin/foo2", "GET")
export class fooreq implements IReturn<string>
{

    public constructor(init?: Partial<fooreq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'fooreq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return ''; }
}

// @Route("/member/feedbackreq", "PATCH")
export class feedbackreq implements IReturnVoid
{
    public feedback: string;

    public constructor(init?: Partial<feedbackreq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'feedbackreq'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() {}
}

// @Route("/member/addressverify", "PATCH")
export class verifyAddressReq implements IReturn<ConsolidatedAddress>
{
    public address: ConsolidatedAddress;

    public constructor(init?: Partial<verifyAddressReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'verifyAddressReq'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new ConsolidatedAddress(); }
}

// @Route("/member/biographical", "PUT")
export class updateBioDataReq implements IReturnVoid
{
    public member: local_member_ui;

    public constructor(init?: Partial<updateBioDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'updateBioDataReq'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/member/availability/types", "GET")
export class getAvailTypesReq implements IReturn<string[]>
{

    public constructor(init?: Partial<getAvailTypesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getAvailTypesReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/member/availability", "GET")
export class getAvailableReq implements IReturn<member_available_dates[]>
{

    public constructor(init?: Partial<getAvailableReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getAvailableReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<member_available_dates>(); }
}

// @Route("/member/availability", "PUT")
export class setAvailableReq implements IReturnVoid
{
    public availability_date: string;
    public availability_type: string;

    public constructor(init?: Partial<setAvailableReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'setAvailableReq'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/member/jobhist", "GET")
export class getmemberjobHistReq implements IReturn<getmemberjobHistResp>
{
    public skip: number;
    public take: number;

    public constructor(init?: Partial<getmemberjobHistReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getmemberjobHistReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new getmemberjobHistResp(); }
}

// @Route("/member/calenderdownload/{Dispatch_Ident}/{Line_Number}/{unique_member_id}")
export class getJobCalendarICSFile implements IReturn<Blob>
{
    public dispatch_Ident: number;
    public line_Number: number;
    public unique_member_id: string;

    public constructor(init?: Partial<getJobCalendarICSFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getJobCalendarICSFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

// @Route("/member", "GET")
export class getlocalmemberReq implements IReturn<local_member_ui>
{

    public constructor(init?: Partial<getlocalmemberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getlocalmemberReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new local_member_ui(); }
}

// @Route("/admin/billtxt", "GET")
export class SendBillingMonthlyText implements IReturn<string>
{

    public constructor(init?: Partial<SendBillingMonthlyText>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendBillingMonthlyText'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return ''; }
}

// @Route("/member/pwd/reset", "PUT")
export class resetPwdRequest implements IReturn<success_response>
{
    public email: string;
    public reset_code: string;
    public new_password: string;

    public constructor(init?: Partial<resetPwdRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'resetPwdRequest'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new success_response(); }
}

// @Route("/member/pwd/emailreset", "GET")
export class emailPwdReset implements IReturn<success_response>
{
    public email: string;

    public constructor(init?: Partial<emailPwdReset>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'emailPwdReset'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new success_response(); }
}

export class validateCaptchaRequest implements IReturn<boolean>
{
    public secret: string;
    public response: string;
    public remoteip: string;

    public constructor(init?: Partial<validateCaptchaRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'validateCaptchaRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return false; }
}

// @Route("/member/register", "POST")
export class registerMemberReq implements IReturn<success_response>
{
    public email: string;
    public pwd: string;
    public invite_code: string;
    public verification_code: string;
    public captcha_value: string;

    public constructor(init?: Partial<registerMemberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'registerMemberReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new success_response(); }
}

// @Route("/stew/masstext", "POST")
export class massTextFromStewReport implements IReturn<massTextFromStewReportResponse>
{
    public sms_message: string;
    public job_ID: string;
    public unique_member_ids: string[];

    public constructor(init?: Partial<massTextFromStewReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'massTextFromStewReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new massTextFromStewReportResponse(); }
}

// @Route("/stew/member/remove", "DELETE")
export class removeMemberFromStewReport implements IReturn<status_message>
{
    public id: number;

    public constructor(init?: Partial<removeMemberFromStewReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'removeMemberFromStewReport'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new status_message(); }
}

// @Route("/stew/member/add", "POST")
export class addMemberToStewReport implements IReturn<StewardReportDetail_ui>
{
    public unique_member_id: string;
    public stewardReportHeaderId: number;
    public job_ID: string;

    public constructor(init?: Partial<addMemberToStewReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'addMemberToStewReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StewardReportDetail_ui(); }
}

// @Route("/stew/membersearch/{search_term}", "GET")
export class searchMember implements IReturn<StewardReportDetail_ui[]>
{
    public search_term: string;

    public constructor(init?: Partial<searchMember>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'searchMember'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<StewardReportDetail_ui>(); }
}

// @Route("/stew/job/member/detail", "PUT")
export class saveStewDetailRecs implements IReturn<number>
{
    public final_submit: boolean;
    public detail_records: StewardReportDetail_ui[];

    public constructor(init?: Partial<saveStewDetailRecs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'saveStewDetailRecs'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return 0; }
}

// @Route("/stew/job/member/{job_id}/{report_date}", "GET")
export class getStewDetailRecordsForJob implements IReturn<StewardReportDetail_ui[]>
{
    public job_id: string;
    public report_date: string;

    public constructor(init?: Partial<getStewDetailRecordsForJob>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getStewDetailRecordsForJob'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<StewardReportDetail_ui>(); }
}

// @Route("/stew/departments", "GET")
export class getDepartments implements IReturn<string[]>
{

    public constructor(init?: Partial<getDepartments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getDepartments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/stew/jobs", "GET")
export class getJobsForSteward implements IReturn<job[]>
{

    public constructor(init?: Partial<getJobsForSteward>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getJobsForSteward'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<job>(); }
}

// @Route("/cli/updateStewardsList", "PUT")
export class updateStewardRoles implements IReturn<updateResp>
{

    public constructor(init?: Partial<updateStewardRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'updateStewardRoles'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new updateResp(); }
}

// @Route("/admin/emailreg/bulk", "POST")
export class sendRegEmailBulk implements IReturnVoid
{
    public member_emails: string;

    public constructor(init?: Partial<sendRegEmailBulk>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'sendRegEmailBulk'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class getRegUrlReq implements IReturn<string>
{
    public memb: localmember;

    public constructor(init?: Partial<getRegUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getRegUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/admin/gettextlog", "GET")
export class GetTextLog implements IReturn<TextLogLocalUnion[]>
{

    public constructor(init?: Partial<GetTextLog>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTextLog'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<TextLogLocalUnion>(); }
}

export class SendText implements IReturn<string>
{
    public unique_member_id: string;
    public job_ID: string;
    public dispatch_Ident: number;
    public phone_number: string;
    public msg: string;
    public url: string;
    public username: string;

    public constructor(init?: Partial<SendText>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendText'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return ''; }
}

// @Route("/{Path}")
export class Fallback
{
    public path: string;

    public constructor(init?: Partial<Fallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Fallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class capturerevreq implements IReturnVoid
{

    public constructor(init?: Partial<capturerevreq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'capturerevreq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class add_memb_stat implements IReturnVoid
{
    public stat_name: string;
    public member: localmember;
    public add_amt: number;

    public constructor(init?: Partial<add_memb_stat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'add_memb_stat'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/cli/sig", "GET")
export class getclientsig implements IReturn<string>
{

    public constructor(init?: Partial<getclientsig>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getclientsig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return ''; }
}

// @Route("/cli/download", "GET")
export class getclientdownload
{

    public constructor(init?: Partial<getclientdownload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'getclientdownload'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

