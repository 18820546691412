import { StewardReportDetail_ui } from "../../services/dtos";
import { TIMESHEET_IDS, TIMESHEET_ID_TO_PROPERTY_MAP } from "../constants";

export const isEndTimeGreaterThanStartTime = (
  startTime: string,
  endTime: string
): boolean => {
  const start = convertTo24Hour(startTime);
  const end = convertTo24Hour(endTime);
  return start < end;
};

export const convertTo24Hour = (time12h: string): string => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
};

export const getTimeDiff = (startTime: string, endTime: string): string => {
  const start = convertTo24Hour(startTime);
  const end = convertTo24Hour(endTime);

  const startSplit = start.split(":");
  const endSplit = end.split(":");

  const startHours = parseInt(startSplit[0], 10);
  const startMinutes = parseInt(startSplit[1], 10);
  const endHours = parseInt(endSplit[0], 10);
  const endMinutes = parseInt(endSplit[1], 10);

  let hourDiff = endHours - startHours;
  let minuteDiff = endMinutes - startMinutes;

  if (minuteDiff < 0) {
    minuteDiff += 60;
    hourDiff--;
  }

  const formattedHourDiff = String(hourDiff).padStart(2, "0");
  const formattedMinuteDiff = String(minuteDiff).padStart(2, "0");

  return `${formattedHourDiff}:${formattedMinuteDiff}`;
};

export const sumOfArray = (iterator: number[]): number => {
  return iterator.reduce((a, b) => a + b);
};

export const getHoursFromTime = (time: string): number => {
  const hours = parseInt(time.split(":")[0]);
  const minutes = parseInt(time.split(":")[1]) / 60;
  return parseFloat((hours + minutes).toFixed(2));
};

const setSelectedJobWithTime = (
  detailedJobRecords: StewardReportDetail_ui[],
  selectedJob: StewardReportDetail_ui,
  timeToSet: string,
  startTimeRegular: string,
  endTimeRegular: string,
  startTimeBreak1: string | undefined,
  endTimeBreak1: string | undefined,
  startTimeBreak2: string | undefined,
  endTimeBreak2: string | undefined,
  timeId: keyof typeof TIMESHEET_IDS
): StewardReportDetail_ui[] => {
  const getBreakHours = (
    start: string | undefined,
    end: string | undefined
  ) => {
    if (start && end) {
      return getHoursFromTime(getTimeDiff(start, end));
    }
    return 0;
  };

  return detailedJobRecords.map((detailedJob: StewardReportDetail_ui) => {
    if (detailedJob.id === selectedJob.id) {
      const regularHours = getHoursFromTime(
        getTimeDiff(startTimeRegular, endTimeRegular)
      );
      const break1Hours = getBreakHours(startTimeBreak1, endTimeBreak1);
      const break2Hours = getBreakHours(startTimeBreak2, endTimeBreak2);

      console.log(
        "setting - " + [TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS[timeId]]]
      );
      console.log({
        ...selectedJob,
        [TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS[timeId]]]: timeToSet,
        qty_hrs_ttl: sumOfArray([
          regularHours - (break1Hours + break2Hours),
          selectedJob.qty_hrs_1_5x,
          selectedJob.qty_hrs_2_0x,
        ]),
        dirty: true,
        qty_hrs_1_5x: 0,
        qty_hrs_2_0x: 0,
      });
      return {
        ...selectedJob,
        [TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS[timeId]]]: timeToSet,
        qty_hrs_ttl: sumOfArray([
          regularHours - (break1Hours + break2Hours),
          selectedJob.qty_hrs_1_5x,
          selectedJob.qty_hrs_2_0x,
        ]),
        dirty: true,
        qty_hrs_1_5x: 0,
        qty_hrs_2_0x: 0,
      };
    }
    console.log(detailedJob);
    return detailedJob;
  });
};

export const setSelectedJobIfAllTimesExist = (
  detailedJobRecords: StewardReportDetail_ui[],
  selectedJob: StewardReportDetail_ui,
  timeToSet: string,
  startTimeRegular: string,
  endTimeRegular: string,
  startTimeBreak1: string,
  endTimeBreak1: string,
  startTimeBreak2: string,
  endTimeBreak2: string,
  timeId: keyof typeof TIMESHEET_IDS
): StewardReportDetail_ui[] => {
  return setSelectedJobWithTime(
    detailedJobRecords,
    selectedJob,
    timeToSet,
    startTimeRegular,
    endTimeRegular,
    startTimeBreak1,
    endTimeBreak1,
    startTimeBreak2,
    endTimeBreak2,
    timeId
  );
};

export const setSelectedJobIf2TimesExist = (
  detailedJobRecords: StewardReportDetail_ui[],
  selectedJob: StewardReportDetail_ui,
  timeToSet: string,
  startTimeRegular: string,
  endTimeRegular: string,
  startTimeBreak1: string,
  endTimeBreak1: string,
  timeId: keyof typeof TIMESHEET_IDS
): StewardReportDetail_ui[] => {
  return setSelectedJobWithTime(
    detailedJobRecords,
    selectedJob,
    timeToSet,
    startTimeRegular,
    endTimeRegular,
    startTimeBreak1,
    endTimeBreak1,
    undefined,
    undefined,
    timeId
  );
};

export const setSelectedJobIf1TimeExists = (
  detailedJobRecords: StewardReportDetail_ui[],
  selectedJob: StewardReportDetail_ui,
  timeToSet: string,
  startTime: string,
  endTime: string,
  timeId: keyof typeof TIMESHEET_IDS
): StewardReportDetail_ui[] => {
  return setSelectedJobWithTime(
    detailedJobRecords,
    selectedJob,
    timeToSet,
    startTime,
    endTime,
    undefined,
    undefined,
    undefined,
    undefined,
    timeId
  );
};

export const setBasicTimeForSelectedJob = (
  detailedJobRecords: StewardReportDetail_ui[],
  selectedJob: StewardReportDetail_ui,
  time: string,
  timeId: keyof typeof TIMESHEET_IDS
): StewardReportDetail_ui[] => {
  console.log(
    "set basic",
    detailedJobRecords.map((detailedJob: StewardReportDetail_ui) => {
      return detailedJob.id === selectedJob.id
        ? {
            ...selectedJob,
            [TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS[timeId]]]: time,
            qty_hrs_1_5x: 0,
            qty_hrs_2_0x: 0,
            dirty: true,
          }
        : detailedJob;
    })
  );
  return detailedJobRecords.map((detailedJob: StewardReportDetail_ui) => {
    return detailedJob.id === selectedJob.id
      ? {
          ...selectedJob,
          [TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS[timeId]]]: time,
          qty_hrs_1_5x: 0,
          qty_hrs_2_0x: 0,
          dirty: true,
        }
      : detailedJob;
  });
};

export const setBasicDetailForSelectedJob = (
  detailedJobRecords: StewardReportDetail_ui[],
  selectedJob: StewardReportDetail_ui,
  value: string,
  propertyName: string
): StewardReportDetail_ui[] => {
  return detailedJobRecords.map((detailedJob: StewardReportDetail_ui) => {
    return detailedJob.id === selectedJob.id
      ? {
          ...selectedJob,
          [propertyName]: value,
          dirty: true,
          qty_hrs_1_5x: 0,
          qty_hrs_2_0x: 0,
        }
      : detailedJob;
  });
};

export const getDateInDatePickerFormat = (date: Date): string => {
  return date.toISOString().slice(0, 10);
};
