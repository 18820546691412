import { job, StewardReportDetail_ui } from "../../services/dtos";
import jobServices from "../../services/jobServices";

export const getStewardDetailedRecordsForJob = async (
  jobId: string,
  reportDate: string
) => {
  let jsvc = new jobServices();
  return jsvc.getStewardDetailedRecordsForJob(jobId, reportDate);
};

export const getJobsForSteward = async (): Promise<job[]> => {
  let jsvc = new jobServices();
  return jsvc.getJobsForSteward();
};

export const addMember = async (
  jobId: string,
  stewReportHeaderId: any,
  uniqueMemberId: string
) => {
  let jsvc = new jobServices();
  return jsvc.addMember(jobId, stewReportHeaderId, uniqueMemberId);
};

export const searchMember = async (
  memberToSearchFor: string
): Promise<StewardReportDetail_ui[]> => {
  let jsvc = new jobServices();
  console.log("member to search for", memberToSearchFor);
  return jsvc.searchMember(memberToSearchFor);
};

export const saveDetailedJobRecords = (
  specificRecordsToSave?: StewardReportDetail_ui[],
  finalSubmit?: boolean,
  detailedJobRecords?: StewardReportDetail_ui[]
) => {
  let jsvc = new jobServices();
  console.log("saveDetailedJobRecords", specificRecordsToSave);
  if (specificRecordsToSave) {
    jsvc
      .saveDetailedJobRecords(specificRecordsToSave, finalSubmit)
      .then((res) => {
        console.log("Saved specifc records ");
        console.log(res);
      });
  } else {
    jsvc.saveDetailedJobRecords(detailedJobRecords, finalSubmit).then((res) => {
      console.log("Saved all records ");
      console.log(res);
    });
  }
};

export const getDepartments = async (): Promise<string[]> => {
  let jsvc = new jobServices();
  return jsvc.getDepartments();
};

export const handleDeleteMember = (
  id: number,
  detailedJobRecords: StewardReportDetail_ui[],
  setDetailedJobRecords: React.Dispatch<
    React.SetStateAction<StewardReportDetail_ui[]>
  >
) => {
  const updatedRecords = detailedJobRecords.filter(
    (record) => record.id !== id
  );
  const jsvc = new jobServices();
  console.log("Deleting " + id);
  jsvc
    .removeMember(id)
    .then((res) => {
      console.log("Deleted " + id);
      console.log(res);
      setDetailedJobRecords(updatedRecords);
    })
    .catch((err) => {
      console.error(err);
      console.log("Error while deleting " + id);
    });
};
