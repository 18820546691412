import { FC,useEffect } from 'react';
import { Spinner,Tooltip } from '../../lib';
import jobServices  from '../../services/jobServices';
import apiServices from '../../services/apiServices';
import { member_job_history }  from '../../services/dtos';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import memberInfoServices  from '../../services/memberInfoSvcs';
import {local_member_ui} from  '../../services/dtos';


const JobHistoryPage: FC = () => {


const [member, setMember] = useState<local_member_ui>(new local_member_ui());


  const [jobHist, setJobHist] = useState<Array<member_job_history>>();
  const [loading, setLoading] = useState(false);

  let svc2 = new apiServices();
  const [baseUrl, setbaseUrl] = useState<string>(svc2.apiUrl());

  let navigate = useNavigate();


  let failed = false;


  
  useEffect(() => {

  if(!jobHist && !loading && !failed)
  {
    setLoading(true);


  let msvc=new memberInfoServices();
  msvc.getMemberInfo().then((data)=>{
 
        setMember(data);
        console.log(data);

      
  });

    let svc = new jobServices();
   
   
  
    svc.getJobHistory().then((data)=>
    {
      setJobHist(data.job_history);
      
        setLoading(false);
        svc.getJobHistory_older().then((olddata)=>
        {
           var ttlhist = data.job_history.concat(olddata.job_history);
           setJobHist(ttlhist);
        });

      
        
    }
    ).catch((e)=>{
        console.log(e);
        setLoading(false);
      if(e.type=="RefreshTokenException" || e.responseStatus.errorCode == "403" || e.responseStatus.errorCode == "401")
      {
        navigate("/login");
  
      }
      if(e.responseStatus.errorCode == "500" || e.responseStatus.errorCode == "404")
      {
        failed=false;
      }

    });
}
  });


  return (
   
  
 <div className="mx-auto flex flex-col  dark:text-gray-400">



        <span className="text-2xl font-bold">Job History</span>
       {loading && <Spinner
    color="success"
   
  />}
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
          
                <th scope="col" className="py-3 px-6">
                  Job #
                </th>
          
                <th scope="col" className="py-3 px-6">
                    Start Date
                </th>
                <th scope="col" className="py-3 px-6">
                   End Date
                </th>
                <th scope="col" className="py-3 px-6">
                    Skill
                </th>
                <th scope="col" className="py-3 px-6">
                    Employer
                </th>
                <th scope="col" className="py-3 px-6">
                    Payroll Co.
                </th>
                <th scope="col" className="py-3 px-6">
                    Job Site
                </th>
                <th scope="col" className="py-3 px-6">
                    Show
                </th>
                <th scope="col" className="py-3 px-6">
                    Location
                </th>
                <th scope="col" className="py-3 px-6">
                    Job Notes
                </th>
                <th scope="col" className="py-3 px-6">
                    Line Notes
                </th>
                <th scope="col" className="py-3 px-6">
                    Contract
                </th>
                <th scope="col" className="py-3 px-6">
                    Rate
                </th>
                <th scope="col" className="py-3 px-6">
                    Dress Code
                </th>
                <th scope="col" className="py-3 px-6">
                    Steward
                </th>
                
                {member.local_feature_toggles?.dont_display_show!="Y" && <th scope="col" className="py-3 px-6">
                    Show
                </th>}
            </tr>
        </thead>
        <tbody>

        
{jobHist && jobHist.length>0 && jobHist.map(({

line_Number,
date_Wrote,
processor,
dispatcher,
wage_Code,
starting_time,
starting_Date,
date_Off_OWL,
ending_Date,
called_By,
posted,
personVoid,
date_Time_Void,
area2,
uploaded,
report_To,
prev_Employer,
prev_Employed_Date,
vac_rate,
dress_Code,
dress_Code2,
finish_Time,
booked,
locked,
lockedBy,
hourly_Rate,
show_Rate,
doNotFill,
job_Detail_id,
hold,
employer,
id,
job_ID,
employer_ID,
job_Description,
address,
city,
state,
zip,
primary_Phone,
fax_Phone,
foreperson,
contact,
start_Date,
end_Date,
area,
active,
atpA_ID,
contract_Expires,
county,
pR_Co_ID,
pR_Phone,
pR_Fax,
call_Placed_By,
show_Id,
site,
job_Notes,
report_To_Location,
report_To_Person,
def_Wage_Code,
external_Empl_JobID,
creator,
createDate,
updater,
updateDate,
duesType,
steward_Rpt_Required,
emailed,
printed,
email,
vacation,
pension,
training,
annuity,
hw,
skill,
steward_Fname,
steward_Lname,
steward_Phone,
show,
ending_date_display,
dispatch_Ident,
unique_member_id,
starting_date_display,
payrollCompany,
lineNotes
}, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td className="py-4 px-6">
                {job_ID}
               
                </td>
                <td className="py-4 px-6">
                {starting_date_display}  {starting_time}
                <Tooltip content="Add to calendar"><a href={baseUrl+"/member/calenderdownload/" + dispatch_Ident + "/" + line_Number + "/" + unique_member_id}><svg  width="30px" height="30px" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"></path>
</svg></a> </Tooltip>
                </td>
               
                <td className="py-4 px-6">
                {ending_date_display} {finish_Time}
                </td>
                
                <td className="py-4 px-6">
                {skill}
                </td>
              
                <td className="py-4 px-6">
                {employer}
                </td>
                <td className="py-4 px-6">
                {payrollCompany}
                </td>
                
                <td className="py-4 px-6">
                {site}
                </td>
                <td className="py-4 px-6">
                {show}
                </td>
                
                <td className="py-4 px-6">
                {report_To_Location}
                </td>
                <td className="py-4 px-6">
                {job_Notes}
                </td>
                <td className="py-4 px-6">
                {lineNotes}
                </td>
                <td className="py-4 px-6">
                {wage_Code}
                </td>
                <td className="py-4 px-6">
                ${hourly_Rate}
                </td>
                <td className="py-4 px-6">
                {dress_Code}
                </td>

                <td className="py-4 px-6">
                {steward_Fname} {steward_Lname} {steward_Phone}
                </td>
                {member.local_feature_toggles?.dont_display_show!="Y" && <td className="py-4 px-6">
                {show}
                </td>}
                
            </tr>))}
       
        </tbody>
    </table>
</div>
      );




};

export default JobHistoryPage;
